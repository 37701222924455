import api from "@/services/api";

import { CredentialType } from "@/types/user/Credential";
import { TokenType } from "@/types/user/Token";

class Authenticate {
  execute = async (credential: CredentialType) => {
    const user = await api().post(`/login`, {
      document: credential.document,
      password: credential.password,
    });
    return user.data as TokenType;
  };
}

export { Authenticate };
