<template>
  <v-container>
    <v-template>
      <title-vue title="Venda online" />
      <v-row>
        <v-col cols="12">
          <v-row class="d-flex justify-space-between">
            <v-col cols="12" md="6">
              <input-text-vue
                label="CPF *"
                :required="true"
                :disabled="loading"
                :readonly="readonly"
                v-model="sale.customerDocument"
                v-mask="'###.###.###-##'"
              />
            </v-col>

            <v-col cols="12" md="2">
              <input-text-vue
                label="Valor *"
                :required="true"
                :disabled="loading"
                :readonly="readonly"
                v-model="sale.value"
                v-money="money"
              />
            </v-col>
            <v-col cols="12" md="4" class="text-right">
              <button-vue
                :block="$vuetify.breakpoint.smAndDown"
                :disabled="loading || sale.value === 'R$ 0,00' || sale.customerDocument === ''"
                type="primary"
                @click="handleQrcode"
                >Gerar QRCode</button-vue
              >
            </v-col>
          </v-row>
          <v-row class="mt-0" v-if="sale.qrcodeImage">
            <v-col cols="12">
              <p class="w-100 text-center subtitle-2">Consumidor: {{ sale.customer.name }}</p>
            </v-col>
            <v-row v-if="!transaction.status && progress > 0">
              <v-col cols="12" class="d-flex justify-center">
                <v-img
                  max-width="250"
                  max-height="250"
                  :src="sale.qrcodeImage"
                  alt="Imagem Base64"
                  contain
                  class="elevation-1 align-self-center"
                ></v-img>
              </v-col>

              <v-col cols="12" class="d-flex justify-center">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <div class="qrcode-container" @click="copyQRCodeText" v-on="on">
                      <p class="text-center subtitle-2 qrcode-text">{{ sale.qrcode }}</p>
                      <v-progress-linear
                        :value="progress"
                        :color="progressColor"
                        height="10"
                        rounded
                        class="progress-transition"
                      ></v-progress-linear>
                    </div>
                  </template>
                  <span>Clique para copiar</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row v-else-if="progress <= 0 && transaction.status !== 'CONFIRMED'">
              <v-col cols="12" class="d-flex justify-center">
                <v-row>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-icon color="red" size="250">mdi-alert-circle</v-icon>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <p class="w-100 text-center subtitle-2">Tempo de venda expirada!</p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-else-if="transaction.status === 'CONFIRMED'">
              <v-col cols="12" class="d-flex justify-center">
                <v-row>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-icon color="green" size="250">mdi-check-circle</v-icon>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <p class="w-100 text-center subtitle-2">Pagamento efetuado com sucesso!</p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" class="d-flex justify-center">
                <v-row>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-icon color="red" size="250">mdi-alert-circle</v-icon>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <p class="w-100 text-center subtitle-2">Erro ao receber o pagamento!</p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-row>
        </v-col>
      </v-row>
    </v-template>
    <v-dialog
      v-model="openDialogNotClient"
      width="600"
      persistent
      transition="dialog-top-transition"
    >
      <v-card>
        <v-card-title><h4>Consumidor não encontrado!</h4></v-card-title>
        <v-card-text>
          <p class="subtitle-2">
            Não encontramos esse consumidor. Deseja cadastrá-lo agora e depois concluir a venda?
          </p>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn elevation="0" text @click="openDialogNotClient = false"> Não </v-btn>
          <v-btn elevation="0" dark color="#e5183d" @click="handleNewClient()"> Sim </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ButtonVue from "@/components/button/Button.vue";
import InputTextVue from "@/components/inputs/InputText.vue";
import TitleVue from "@/components/title/Title.vue";

import { GetQrcode } from "@/services/sales/GetQrcode";
import { GetTransactionListener } from "@/services/sales/GetTransactionListener";

import { format } from "date-fns";
import { SaleDetailsType, TransactionType } from "@/types/sale/Sale";

export default defineComponent({
  components: { ButtonVue, InputTextVue, TitleVue },
  data() {
    return {
      openDialogNotClient: false,
      totalTime: 60 * 60,
      elapsedTime: 0,
      progress: 100,
      progressColor: "success",
      timer: null as any,
      loading: false,
      title: "Nova venda",
      readonly: false,
      sale: {
        customerDocument: "",
        value: 0,
        description: "",
        qrcode: "",
        qrcodeImage: "",
        customer: {
          name: "",
          document: "",
          nickname: "",
        },
      } as SaleDetailsType,
      transaction: {
        id: "",
        value: 0,
        description: "",
        createdAt: "",
        status: null,
        customer: {
          name: "",
          document: "",
        },
        partner: {
          name: "",
          document: "",
          pixkey: "",
        },
      } as TransactionType,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
    };
  },
  methods: {
    handleNewClient() {
      this.$router.push({
        name: "clientForm",
      });
      this.openDialogNotClient = false;
    },
    async handleQrcode() {
      this.loading = true;

      const getQrcode = new GetQrcode();
      this.transaction.status = null;
      this.sale.qrcodeImage = "";
      await getQrcode
        .execute(this.sale, 3600)
        .then(async (res) => {
          this.sale.qrcodeImage = res.qrcodeImage;
          this.sale.customer.document = res.customer.document;
          this.sale.customer.name = res.customer.name;
          this.sale.customer.nickname = res.customer.nickname;
          this.sale.qrcode = res.qrcode;
          this.handleStartTime();
          const getTransactionListener = new GetTransactionListener();
          await getTransactionListener
            .execute(this.sale.qrcode)
            .then((res) => {
              this.transaction.status = res.status;
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          if (err.response.status === 404) {
            this.openDialogNotClient = true;
          }
        })
        .finally(() => {
          clearInterval(this.timer);
          this.loading = false;
        });
    },
    handleStartTime() {
      this.progress = 100;
      this.elapsedTime = 0;
      this.timer = setInterval(() => {
        this.elapsedTime += 1;
        this.updateProgress();
        if (this.progress <= 100 && this.progress < 50) {
          this.progressColor = "success";
        }
        if (this.progress <= 50 && this.progress > 20) {
          this.progressColor = "warning";
        } else if (this.progress <= 20 && this.progress > 0) {
          this.progressColor = "red darken-2";
        }
        if (this.elapsedTime >= this.totalTime) {
          clearInterval(this.timer);
          this.loading = false;
        }
      }, 1000);
    },
    updateProgress() {
      this.progress = ((this.totalTime - this.elapsedTime) / this.totalTime) * 100;
    },
    copyQRCodeText() {
      const qrCodeText = this.sale.qrcode;

      const textarea = document.createElement("textarea");
      textarea.value = qrCodeText;
      document.body.appendChild(textarea);

      textarea.select();
      document.execCommand("copy");

      document.body.removeChild(textarea);
      this.$store.dispatch("showSnack", {
        message: "QRCode copiado com sucesso",
        color: "blue darken-1",
      });
    },
    formatPhone: (value: string): string => {
      if (!value) return "";
      const phone = "(" + value.substr(0, 2) + ") " + value.substr(2, 5) + "-" + value.substr(7, 8);
      return phone;
    },
    formatDate: (date: string): string => {
      const dateFormated = format(new Date(date), "dd/MM/yyyy HH:mm:ss");
      return dateFormated;
    },
    formatZipCod: (cep: string): string => {
      return cep.replace(/(\d{5})(\d{3})/, "$1-$2");
    },
    formatDocument: (document: string): string => {
      return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    },
  },
});
</script>
<style scoped>
.label {
  color: #333 !important;
  font-weight: 600;
  margin-bottom: 2px;
  font-size: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.qrcode-text {
  word-wrap: break-word;
}

.qrcode-container {
  width: 250px;
  text-align: center;
}
.progress-transition {
  transition: width 1s;
}
</style>
