<template>
  <h3>{{ title }}</h3>
</template>

<script>
export default {
  props: ["title"],
};
</script>

<style scoped lang="scss">
h3 {
  color: var(--darker) !important;
  font-weight: 600;
}
</style>