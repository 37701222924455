<template>
  <v-skeleton-loader
    v-if="!!loading"
    tile
    loading
    class="mx-auto"
    type="card"
    elevation="{5}"
    boilerplate
  />
  <v-container v-else>
    <v-dialog v-model="dialog" width="800">
      <v-card width="800" height="800">
        <figure>
          <img
            style="object-fit: cover; width: 100%; height: 100%"
            :src="partner.logoImage"
            :alt="`logotipo da empresa ${partner.name}`"
          />
        </figure>
      </v-card>
    </v-dialog>
    <v-row class="header d-flex align-center" align="start">
      <v-col cols="12" sm="6" md="4" class="d-flex align-center">
        <title-vue title="Detalhes do parceiro" />
      </v-col>

      <v-col cols="12" sm="6" md="8" class="d-flex justify-end">
        <v-btn elevation="0" color="primary" dark @click="changeRouteToEdit" class="mr-3">
          Editar dados
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              elevation="0"
              color="#e5183d"
              class="white--text animation"
              v-bind="attrs"
              v-on="on"
            >
              Baixar QRCODE
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="handleQRCODEStatic"
              :disabled="
                partner.digitalAccountNumber.length <= 0 ||
                partner.digitalAccountDocument.length <= 0
              "
            >
              <v-list-item-title>Baixar QRCODE estático</v-list-item-title>
            </v-list-item>
            <v-list-item @click="handleQRCODEEnvite">
              <v-list-item-title>Baixar QRCODE de indicação</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-row class="mt-2">
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <p class="subtitle-2">Logotipo \ Logomarca</p>
            <v-row>
              <v-col cols="12">
                <v-avatar
                  size="117"
                  class="cursor-alter"
                  v-if="partner.logoImage !== ''"
                  @click="dialog = !dialog"
                >
                  <figure>
                    <img
                      style="object-fit: cover; width: 117px; height: 117px"
                      :src="partner.logoImage"
                      :alt="`logotipo da empresa ${partner.name}`"
                    />
                  </figure>
                </v-avatar>
                <v-avatar v-else size="117" color="#e5183d">
                  <v-icon color="#FFF" size="117" dark> mdi-account-circle </v-icon>
                </v-avatar>
              </v-col>
            </v-row>
            <p class="subtitle-2 mt-4">Dados principais</p>
            <v-row>
              <v-col cols="12" md="4">
                <title-subtitle-vue
                  :label="partner.typeCompany == 'PJ' ? 'Razão social' : 'Nome completo'"
                  :value="partner.name"
                />
              </v-col>
              <v-col cols="12" md="4">
                <title-subtitle-vue
                  :label="partner.typeCompany == 'PJ' ? 'Nome fantasia' : 'Como quer ser chamado'"
                  :value="partner.nickname"
                />
              </v-col>
              <v-col cols="12" md="4">
                <title-subtitle-vue
                  :label="partner.typeCompany == 'PJ' ? 'CNPJ' : 'CPF'"
                  :value="formatDocument(partner.document)"
                />
              </v-col>
              <v-col cols="12" md="4" v-if="partner.typeCompany === 'PJ'">
                <title-subtitle-vue label="CNAE" :value="partner.cnaeCode" />
              </v-col>

              <v-col cols="12" md="4">
                <title-subtitle-vue label="Email" :value="partner.email" />
              </v-col>
              <v-col cols="12" md="4">
                <title-subtitle-vue label="Telefone" :value="formatPhone(partner.phone)" />
              </v-col>
              <v-col cols="12" md="4">
                <title-subtitle-vue label="Categoria" :value="partner.category" />
              </v-col>
            </v-row>
            <hr />
            <p class="subtitle-2 mt-4">Dados de endereço</p>
            <v-row>
              <v-col cols="12" md="4">
                <title-subtitle-vue label="Logradouro" :value="partner.address.street" />
              </v-col>
              <v-col cols="12" md="2">
                <title-subtitle-vue label="Número" :value="partner.address.addressNumber" />
              </v-col>
              <v-col cols="12" md="3">
                <title-subtitle-vue label="Bairro" :value="partner.address.neighborhood" />
              </v-col>
              <v-col cols="12" md="3">
                <title-subtitle-vue label="Complemento" :value="partner.address.complement" />
              </v-col>
              <v-col cols="12" md="4">
                <title-subtitle-vue label="CEP" :value="formatZipCod(partner.address.zipCode)" />
              </v-col>
              <v-col cols="12" md="4">
                <title-subtitle-vue label="Cidade" :value="partner.address.cityName" />
              </v-col>
              <v-col cols="12" md="4">
                <title-subtitle-vue label="Estado" :value="partner.address.state" />
              </v-col>
            </v-row>
            <hr />

            <p class="subtitle-2 mt-4">Dados bancários</p>
            <v-row>
              <v-col cols="12" md="2">
                <title-subtitle-vue
                  label="Documento do titular"
                  :value="formatDocument(partner.digitalAccountDocument)"
                />
              </v-col>
              <v-col cols="12" md="2">
                <title-subtitle-vue label="Número da conta" :value="partner.digitalAccountNumber" />
              </v-col>

              <v-col cols="12" md="6">
                <title-subtitle-vue label="Chave PIX" :value="partner.pixkey" />
              </v-col>
              <!-- <v-col cols="12" md="6">
                <title-subtitle-vue label="Taxa administrativa" :value="`${partner.tax}%`" />
              </v-col> -->
            </v-row>
            <hr />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="showImageDialog" width="100%" height="600px">
      <v-card>
        <v-img :src="clickedImage" contain></v-img>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showPdfDialog">
      <v-card>
        <object :data="clickedPdf" type="application/pdf" width="100%" height="600px"></object>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import TitleVue from "@/components/title/Title.vue";
import TitleSubtitleVue from "@/components/titleSubtitle/TitleSubtitle.vue";
import { GetPartner } from "@/services/partners/GetPartner";

import { PartnerDetailsType } from "@/types/partner/Partner";

import QrCode from "qrcode";
import { format } from "date-fns";
export default defineComponent({
  components: { TitleVue, TitleSubtitleVue },
  props: ["mode", "pClient"],
  data() {
    return {
      loading: false,
      showImageDialog: false,
      clickedImage: "",
      showPdfDialog: false,
      clickedPdf: "",
      dialog: false,
      partner: {
        id: "",
        name: "",
        status: "PENDING",
        nickname: "",
        tax: 5,
        typeCompany: "PJ",
        category: "",
        phone: "",
        email: "",
        document: "",
        pixkey: "",
        logoImage: "",
        cnaeCode: "",
        indicationKey: "",
        address: {
          state: "",
          street: "",
          addressNumber: "",
          neighborhood: "",
          complement: "",
          zipCode: "",
          cityName: "",
          ibge: "",
        },
        users: [],
        user: {
          name: "",
          email: "",
          phone: "",
          document: "",
          password: "",
          nickname: "",
          birthdate: "",
          motherName: "",
        },
        associate: [],
        businessHours: [],
        createdAt: "",
        digitalAccountNumber: "",
        digitalAccountDocument: "",
        associates: [],
        categoryId: "1",
        contractFile: "",
        registrationStatusFile: "",
        partnerConfigs: {
          id: 0,
          onlineSale: false,
          pdvSale: false,
          instantCashback: 0,
          updatedAt: "",
        },
      } as PartnerDetailsType,
      headersAssociates: [
        {
          text: "Nome",
          align: "center",
          value: "name",
        },
        {
          text: "CPF",
          align: "center",
          value: "cpf",
          textAlign: "center",
        },
        {
          text: "RG",
          align: "center",
          value: "rg",
        },
      ],
      headersBusinessHours: [
        {
          text: "Dia",
          align: "center",
          value: "day",
        },
        {
          text: "Abertura",
          align: "center",
          value: "startTime",
        },
        {
          text: "Fechamento",
          align: "center",
          value: "endTime",
        },
      ],
    };
  },
  methods: {
    async handleLoadOnboarding() {
      this.loading = true;
      const getPartner = new GetPartner();
      const partner = await getPartner.execute();
      partner.document.length > 11
        ? (this.partner.typeCompany = "PJ")
        : (this.partner.typeCompany = "PF");
      this.partner.id = partner.id || "";
      this.partner.name = partner.name || "";
      this.partner.nickName = partner.nickname || "";
      this.partner.document = partner.document || "";
      this.partner.cnaeCode = partner.cnaeCode || "";
      this.partner.indicationKey = partner.indicationKey || "";
      this.partner.category = partner.category || "";
      this.partner.tax = partner.tax || 0;
      this.partner.status = partner.status;
      this.partner.createdAt = partner.createdAt || "";
      this.partner.digitalAccountDocument = partner.digitalAccountDocument || "";
      this.partner.digitalAccountNumber = partner.digitalAccountNumber || "";
      this.partner.pixkey = partner.pixkey || "";
      this.partner.logoImage = partner.logoImage || "";
      this.partner.phone = partner.phone || "";
      this.partner.email = partner.email || "";
      this.partner.address.street = partner.address.street || "";
      this.partner.address.addressNumber = partner.address.addressNumber || "";
      this.partner.address.neighborhood = partner.address.neighborhood || "";
      this.partner.address.complement = partner.address.complement || "";
      this.partner.address.zipCode = partner.address.zipCode || "";
      this.partner.address.cityName = partner.address.cityName || "";
      this.partner.address.state = partner.address.state || "";
      this.partner.user = partner.users[0];
      this.partner.associates = partner.associates || [];
      this.partner.businessHours = partner.businessHours || [];
      this.partner.contractFile = partner.contractFile || "";
      this.partner.registrationStatusFile = partner.registrationStatusFile || "";
      this.partner.partnerConfigs.id = partner.partnerConfigs.id || 0;
      this.partner.partnerConfigs.onlineSale = partner.partnerConfigs.onlineSale || false;
      this.partner.partnerConfigs.pdvSale = partner.partnerConfigs.pdvSale || false;
      this.partner.partnerConfigs.updatedAt = partner.partnerConfigs.updatedAt || "";
      this.partner.partnerConfigs.instantCashback = partner.partnerConfigs.instantCashback || 0;
      this.loading = false;
    },

    handleCancel() {
      this.$router.push("/onboarding");
    },
    formatPhone: (value: string): string => {
      if (!value) return "";
      const phone = "(" + value.substr(0, 2) + ") " + value.substr(2, 5) + "-" + value.substr(7, 8);
      return phone;
    },
    formatDate: (date: string): string => {
      const dateFormated = format(new Date(date), "dd/MM/yyyy HH:mm:ss");
      return dateFormated;
    },
    formatZipCod: (cep: string): string => {
      return cep.replace(/(\d{5})(\d{3})/, "$1-$2");
    },
    formatDocument: (document: string): string => {
      if (document.length === 11) {
        return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      } else if (document.length === 14) {
        return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
      } else {
        return document;
      }
    },
    handleImageClick(imageUrl: string) {
      this.clickedImage = imageUrl;
      this.showImageDialog = true;
    },
    handlePdfClick(pdfUrl: string) {
      this.clickedPdf = pdfUrl;
      this.showPdfDialog = true;
    },
    changeRouteToEdit() {
      this.$router.push({ name: "MainData" });
    },

    async handleQRCODEEnvite() {
      try {
        const qrdata = `https://site.brpoints.com.br/indication/?type=PARTNER&code=${this.partner.indicationKey}`;
        const data = await QrCode.toDataURL(qrdata);
        const link = document.createElement("a");
        link.href = data;
        link.download = `qrcode-indicação-${this.partner.name}.png`;
        link.click();
      } catch (e) {
        console.error(e);
      }
    },
    async handleQRCODEStatic() {
      try {
        const qrdata = `mode=static&${this.partner.document}&${this.partner.name}&${this.partner.id}`;
        const data = await QrCode.toDataURL(qrdata);
        const link = document.createElement("a");
        link.href = data;
        link.download = `qrcode-pagamento-estático-${this.partner.name}.png`;
        link.click();
      } catch (e) {
        console.error(e);
      }
    },
  },
  mounted() {
    this.handleLoadOnboarding();
  },
});
</script>
<style scoped>
.label {
  color: #333 !important;
  font-weight: 600;
  margin-bottom: 2px;
  font-size: 16px;
}
.img-document {
  cursor: zoom-in;
}
.cursor-alter:hover {
  cursor: pointer;
}
.v-avatar {
  border-radius: 0;
}
.animation {
  transition: all 0.5s ease-out;
}

.flex-column {
  flex-direction: column !important;
}

.flex-md-row {
  @media (min-width: 960px) {
    flex-direction: row !important;
  }
}
</style>
