<template>
  <v-app>
    <v-container fluid class="fill-height">
      <v-row class="fill-height align-center justify-center">
        <v-col
          cols="12"
          md="8"
          class="justify-start align-center text-column d-none d-md-flex d-md-none"
        >
          <div class="text-content">
            <h2><strong>BRPoints</strong>, sua melhor plataforma de fidelização e cashback!</h2>
            <ul>
              <li>- Fidelização de clientes</li>
              <li>- Dinheiro caindo na hora</li>
              <li>- Bônus de 10% no cashback dos clientes indicados</li>
              <li>- E muito mais...</li>
            </ul>
            <v-btn
              class="mt-4"
              elevation="0"
              color="#fafafa"
              href="https://brpoints.com.br/parceiros/"
              target="_blank"
              >Conheça nosso site</v-btn
            >
          </div>
          <v-img :src="background" alt="Imagem de Fundo" class="background-image"></v-img>
        </v-col>
        <v-col cols="12" md="4" class="d-flex justify-center align-center">
          <v-card class="v-card-form">
            <v-card-title class="mt-4 mb-4">
              <v-list-item class="d-sm-flex d-md-none">
                <v-list-item-content>
                  <v-list-item-avatar>
                    <v-img contain :src="logo" alt="LOGO BRP"></v-img>
                  </v-list-item-avatar>
                </v-list-item-content>
              </v-list-item>

              <div>
                <h5 class="subtitle-1 text--primary mb-2">Olá! Seja bem-vindo a BRPoints</h5>
                <h5 class="subtitle-2 text--secondary">
                  Acesse o sistema e aumente as suas vendas
                </h5>
              </div>
            </v-card-title>

            <v-card-text>
              <v-form ref="form" v-model="valid" @submit.prevent="login">
                <v-text-field
                  v-model="user.document"
                  label="CPF"
                  outlined
                  v-mask="'###.###.###-##'"
                  :rules="rules"
                ></v-text-field>

                <v-text-field
                  v-model="user.password"
                  label="Senha"
                  outlined
                  append-icon="mdi-eye"
                  :rules="rules"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>

                <v-btn
                  type="submit"
                  :disabled="!valid"
                  :dark="valid"
                  color="#ee2c4f"
                  class="mt-5"
                  block
                >
                  Entrar
                </v-btn>
                <div class="d-flex align-center justify-end">
                  <v-btn
                    type="button"
                    class="mt-2 mb-2 text-right"
                    color="primary"
                    dark="valid"
                    text
                    @click="handleForgotPassword"
                  >
                    Esqueci a senha
                  </v-btn>
                </div>

                <v-btn elevation="0" dark block color="#e5183d" @click="createAccount"
                  >Quero ser parceiro</v-btn
                >
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Authenticate } from "@/services/user/Authenticate";
import { CredentialType } from "@/types/user/Credential";
import { TokenType } from "@/types/user/Token";
import { GetPartner } from "@/services/partners/GetPartner";
export default defineComponent({
  data() {
    return {
      valid: false,
      user: {
        document: "",
        password: "",
      } as CredentialType,
      rules: [
        (value: string) => {
          if (value) return true;
          return "O campo é obrigatório";
        },
      ],
      showPassword: false,
      logo: require("../../assets/logo-login.jpg"),
      background: require("../../assets/background.png"),
    };
  },
  methods: {
    async login() {
      const authenticate = new Authenticate();
      await authenticate
        .execute(this.user)
        .then(async (res) => {
          const tokens = {
            accessToken: res.accessToken,
            refreshToken: res.refreshToken,
          } as TokenType;
          const tokensString = JSON.stringify(tokens);
          localStorage.setItem("tokens", tokensString);
          const getPartner = new GetPartner();
          const partner = await getPartner.execute();
          this.$store.dispatch("setSessionInfo", {
            name: partner.name,
            nickname: partner.nickname,
          });
          this.$router.push("/");
        })
        .catch(() => {
          this.$store.dispatch("showSnack", {
            message: "Erro ao efetuar o login",
            color: "red",
          });
        });
    },
    handleForgotPassword() {
      this.$router.push("/forgot-password");
    },
    createAccount() {
      this.$router.push("/onboarding");
    },
  },
});
</script>

<style scoped>
.v-application {
  background: #e5183d !important;
  height: 100vh;
}

.fill-height {
  height: 100vh;
}

.background-container {
  display: flex;
  align-items: flex-end;
  padding: 0;
  overflow: hidden;
}

.background-image {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  margin: 0;
  position: absolute;
  z-index: -1;
}

.text-column {
  position: relative;
  z-index: 1;
}

.text-content {
  color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 450px;
  margin-left: 20px;
}

.text-content h2 {
  font-size: 32px;
  font-weight: 800;
  margin-bottom: 60px;
}

.text-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.text-content ul li {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
}

.v-card {
  background-color: #fafafa;
  box-shadow: 0 0 2px rgba(255, 255, 255, 0.9) !important;
}

.v-card-form {
  width: 100%;
  max-width: 400px;
}

.v-card-title {
  background-color: #3f51b5;
  color: white;
}

.v-card-text {
  padding: 24px;
}

.text-center {
  text-align: center;
}

@media (max-width: 960px) {
  .v-application {
    background-color: #fafafa !important;
  }
  .v-card-form {
    width: 100vw !important;
    height: 100vh !important;
  }
}
</style>
