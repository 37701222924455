<template>
  <v-skeleton-loader
    v-if="!!loading"
    tile
    loading
    class="mx-auto"
    type="card"
    elevation="{5}"
    boilerplate
  />
  <v-container v-else>
    <header class="d-flex">
      <TitleVue title="Configurações" />
    </header>

    <v-row class="mt-3">
      <v-col cols="12" md="2">
        <v-checkbox
          v-model="partner.partnerConfigs.onlineSale"
          label="Venda online"
          @change="handleUpdatePartner()"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" md="3">
        <v-checkbox
          v-model="partner.partnerConfigs.pdvSale"
          label="Venda em PDV"
          @change="handleUpdatePartner()"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" md="7">
        <v-row dense>
          <v-col cols="12" md="6" class="d-flex align-start pa-0" style="align-items: end">
            <div>
              <label class="text-subtitle-1 mb-2 d-flex align-center">
                Taxa administrativa
                <v-tooltip bottom color="#e5183d">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small class="ml-2" color="grey">
                      mdi-help-circle
                    </v-icon>
                  </template>
                  <span class="subtitle-2">
                    Ao aplicar a taxa de {{ partner.tax }}%, seu cliente receberá 1 BRP para cada
                    <strong>{{ calculatedBRP }}</strong> em compras.
                  </span>
                </v-tooltip>
              </label>
              <v-slider
                min="3"
                max="100"
                :disabled="loading"
                v-model="partner.tax"
                thumb-color="#e5183d"
                track-fill-color="#e5183d"
                track-color="#f57990"
                @input="handleTaxChange"
              >
                <template v-slot:append> {{ partner.tax }}% </template>
              </v-slider>
            </div>
          </v-col>
          <v-col cols="12" md="6" class="d-flex align-start pa-0" style="align-items: end">
            <div>
              <label class="text-subtitle-1 mb-2"
                >Cashback direto
                <v-tooltip bottom color="#e5183d">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small class="ml-2" color="grey">
                      mdi-help-circle
                    </v-icon>
                  </template>
                  <span class="subtitle-2"
                    >O cashback direto é a porcentagem que é imediatamente creditada no saldo do
                    cliente no momento da compra.</span
                  >
                </v-tooltip></label
              >
              <v-slider
                min="0"
                max="100"
                :disabled="loading"
                v-model="partner.partnerConfigs.instantCashback"
                thumb-color="#e5183d"
                track-fill-color="#e5183d"
                track-color="#f57990"
                @input="handleCashbackChange"
              >
                <template v-slot:append> {{ partner.partnerConfigs.instantCashback }}% </template>
              </v-slider>
            </div>
          </v-col>
          <v-col cols="12" class="d-flex pa-0">
            <p class="subtitle-2" style="margin-top: -20px">
              Ex.: Em uma venda de R$ 100,00, você receberá em sua conta
              {{ formatPrice(100 - partner.tax - partner.partnerConfigs.instantCashback) }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import TitleVue from "@/components/title/Title.vue";
import { defineComponent } from "vue";
import { PartnerDetailsType } from "@/types/partner/Partner";
import { GetPartner } from "@/services/partners/GetPartner";
import { UpdateSettings } from "@/services/settings/UpdateSettings";
import { UpdatePartner } from "@/services/partners/UpdatePartner";
export default defineComponent({
  components: { TitleVue },
  data() {
    return {
      loading: false,
      partner: {
        id: "",
        name: "",
        status: "PENDING",
        nickname: "",
        tax: 5,
        typeCompany: "PJ",
        category: "",
        phone: "",
        email: "",
        document: "",
        pixkey: "",
        cnaeCode: "",
        address: {
          state: "",
          street: "",
          addressNumber: "",
          neighborhood: "",
          complement: "",
          zipCode: "",
          cityName: "",
          ibge: "",
        },
        users: [],
        user: {
          name: "",
          email: "",
          phone: "",
          document: "",
          password: "",
          nickname: "",
          birthdate: "",
          motherName: "",
        },
        associate: [],
        businessHours: [],
        createdAt: "",
        digitalAccountNumber: "",
        digitalAccountDocument: "",
        associates: [],
        categoryId: "",
        contractFile: "",
        registrationStatusFile: "",
        partnerConfigs: {
          id: 0,
          onlineSale: false,
          pdvSale: false,
          updatedAt: "",
          instantCashback: 0,
        },
      } as PartnerDetailsType,
      categories: [],
      files: null,
      avatarPhoto: "",
      dialog: false,
    };
  },
  methods: {
    changeRouter() {
      this.$router.push({ name: "myData" });
    },
    async handleGetDataPartner() {
      this.loading = true;
      const getPartner = new GetPartner();
      const partner = await getPartner.execute();

      partner.document.length > 11
        ? (this.partner.typeCompany = "PJ")
        : (this.partner.typeCompany = "PF");
      this.partner.id = partner.id || "";
      this.partner.name = partner.name || "";
      this.partner.nickName = partner.nickname || "";
      this.partner.document = partner.document || "";
      this.partner.cnaeCode = partner.cnaeCode || "";
      this.partner.category = partner.category || "";
      this.partner.categoryId = partner.categoryId || "";
      this.partner.tax = partner.tax || 0;
      this.partner.status = partner.status;
      this.partner.createdAt = partner.createdAt || "";
      this.partner.digitalAccountDocument = partner.digitalAccountDocument || "";
      this.partner.digitalAccountNumber = partner.digitalAccountNumber || "";
      this.partner.pixkey = partner.pixkey || "";
      this.partner.phone = partner.phone || "";
      this.partner.email = partner.email || "";
      this.partner.address.street = partner.address.street || "";
      this.partner.address.ibge = partner.address.ibge;
      this.partner.address.addressNumber = partner.address.addressNumber || "";
      this.partner.address.neighborhood = partner.address.neighborhood || "";
      this.partner.address.complement = partner.address.complement || "";
      this.partner.address.zipCode = partner.address.zipCode || "";
      this.partner.address.cityName = partner.address.cityName || "";
      this.partner.address.state = partner.address.state || "";
      this.partner.user = partner.users[0];
      this.partner.associates = partner.associates || [];
      this.partner.businessHours = partner.businessHours || [];
      this.partner.contractFile = partner.contractFile || "";
      this.partner.registrationStatusFile = partner.registrationStatusFile || "";
      this.partner.partnerConfigs.id = partner.partnerConfigs.id || 0;
      this.partner.partnerConfigs.onlineSale = partner.partnerConfigs.onlineSale || false;
      this.partner.partnerConfigs.pdvSale = partner.partnerConfigs.pdvSale || false;
      this.partner.partnerConfigs.updatedAt = partner.partnerConfigs.updatedAt || "";
      this.partner.partnerConfigs.instantCashback = partner.partnerConfigs.instantCashback || 0;
      this.loading = false;
    },
    handleTaxChange(newValue: number) {
      const currentCashback = this.partner.partnerConfigs.instantCashback;
      const total = newValue + currentCashback;

      if (total <= 100) {
        this.partner.tax = newValue;
        this.handleUpdatePartner();
      } else {
        this.partner.tax = 100 - currentCashback;
      }
    },
    handleCashbackChange(newValue: number) {
      const currentTax = this.partner.tax;
      const total = currentTax + newValue;

      if (total <= 100) {
        this.partner.partnerConfigs.instantCashback = newValue;
        this.handleUpdatePartner();
      } else {
        this.partner.partnerConfigs.instantCashback = 100 - currentTax;
      }
    },
    handleUpdatePartner() {
      const updatePartnerSettings = new UpdateSettings();
      const updatePartnerData = new UpdatePartner();
      if (!this.partner.partnerConfigs.pdvSale && !this.partner.partnerConfigs.pdvSale) {
        this.partner.partnerConfigs.pdvSale = true;
      }
      updatePartnerSettings.execute(this.partner);
      updatePartnerData.execute(this.partner);
    },
    formatPrice(value: number): string {
      if (!value && value !== 0) return "";
      const formattedValue = value.toFixed(2).replace(".", ",");
      return `R$ ${formattedValue}`;
    },
  },
  mounted() {
    this.handleGetDataPartner();
  },
  computed: {
    calculatedBRP(): string {
      let value = ((2 / this.partner.tax) * 100).toFixed(2).replace(".", ",");
      return `R$ ${value}`;
    },
  },
});
</script>

<style scoped></style>
