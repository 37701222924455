import Vue from "vue";
import VueRouter, { NavigationGuard } from "vue-router";
import HomeView from "../views/home/HomeView.vue";
import ClientView from "@/views/client/ClientView.vue";
import ClientFormView from "@/views/client/ClientFormView.vue";
import SalesView from "@/views/sales/SalesView.vue";
import SalesNewView from "@/views/sales/SalesNewView.vue";
import SalesOnlineNewView from "@/views/sales/SalesNewOnlineView.vue";
import OnboardingFormView from "@/views/onboarding/OnboardingFormView.vue";
import Login from "../views/login/Login.vue";
import ForgotPassword from "../views/login/ForgotPassword.vue";
import PartnerDetailView from "@/views/partner/PartnerDetail.vue";
import MainData from "@/views/mainData/MainData.vue";
import SettingsVue from "@/views/settings/Settings.vue";

Vue.use(VueRouter);

const requireAuth: NavigationGuard = (to, from, next) => {
  const userData = JSON.parse(String(localStorage.getItem("tokens")));
  let token = false;
  if (userData) {
    token = userData.accessToken;
  }
  if (token && (to.path === "/login" || to.path === "/forgot-password")) {
    next("/");
  } else if (token) {
    next();
  } else {
    next("/login");
  }
};

const routes = [
  {
    path: "/main-data-edit",
    name: "MainData",
    component: MainData,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/onboarding",
    name: "onboarding",
    component: OnboardingFormView,
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/clients",
    name: "clients",
    component: ClientView,
    beforeEnter: requireAuth,
  },
  {
    path: "/client/form/",
    name: "clientForm",
    component: ClientFormView,
    beforeEnter: requireAuth,
  },
  {
    path: "/client/form/:id",
    name: "clientDetail",
    component: ClientFormView,
    beforeEnter: requireAuth,
  },
  {
    path: "/sales",
    name: "sales",
    component: SalesView,
    beforeEnter: requireAuth,
  },
  {
    path: "/my-data",
    name: "myData",
    component: PartnerDetailView,
    beforeEnter: requireAuth,
  },
  {
    path: "/sales/new",
    name: "saleNew",
    component: SalesNewView,
    beforeEnter: requireAuth,
  },
  {
    path: "/sales-online/new",
    name: "saleOnlineNew",
    component: SalesOnlineNewView,
    beforeEnter: requireAuth,
  },

  {
    path: "/settings",
    name: "settings",
    component: SettingsVue,
    beforeEnter: requireAuth,
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    beforeEnter: requireAuth,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
