import api from "@/services/api";
import { DashboardType } from "@/types/dashboard/Dashboard";
class GetDashboard {
  execute = async (startDate: String | undefined, endDate: String | undefined) => {
    const dashboard = await api().get(`dashboard/data`, {
      params: {
        startDate: startDate,
        endDate: endDate,
      },
    });

    return dashboard.data as DashboardType;
  };
}

export { GetDashboard };
