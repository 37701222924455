<template>
  <v-app id="app">
    <v-dialog v-model="openExit" width="600" persistent transition="dialog-top-transition">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <h2 class="black--text">Deseja realmente sair?</h2>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn elevation="0" text @click="openExit = !openExit"> Não </v-btn>
          <v-btn elevation="0" dark color="#e5183d" @click="handleLogout"> Sim </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer
      v-if="
        this.$route.name !== 'login' &&
        this.$route.name !== 'forgotPassword' &&
        this.$route.name !== 'onboarding'
      "
      dark
      v-model="drawer"
      app
      class="custom-gradient no-print"
    >
      <v-list-item>
        <v-list-item-content class="text-center">
          <v-list-item-avatar class="mb-2">
            <v-img width="60" height="50" contain :src="logo" alt="LOGO BRP"></v-img>
          </v-list-item-avatar>

          <v-list-item-subtitle> Parceiros </v-list-item-subtitle>
          <v-list-item-subtitle>{{ sessionInfo.name }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.to"
          link
          @click="handleChangeTitle(item.title)"
          class="no-print"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      v-if="
        this.$route.name !== 'login' &&
        this.$route.name !== 'forgotPassword' &&
        this.$route.name !== 'onboarding'
      "
      app
      absolute
      dark
      color="#e5183d"
      height="66.8px"
      class="no-print"
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ title }}</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn icon @click="openExit = !openExit">
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-snackbar
        v-model="snackbar.active"
        :color="snackbar.color"
        :right="true"
        :top="true"
        :timeout="snackbar.timer"
      >
        {{ snackbar.message }}
        <template #action="{ attrs }">
          <v-icon color="white" v-bind="attrs" @click="snackbar.active = false">mdi-close</v-icon>
        </template>
      </v-snackbar>
      <router-view
        :class="
          this.$route.name !== 'login' &&
          this.$route.name !== 'forgotPassword' &&
          this.$route.name !== 'onboarding'
            ? 'pa-4'
            : ''
        "
      />
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { GetPartner } from "@/services/partners/GetPartner";

export default {
  name: "App",

  data: () => ({
    openExit: false,
    drawer: null,
    items: [
      { title: "Home", to: "/" },
      { title: "Vendas", to: "/sales" },
      { title: "Clientes", to: "/clients" },
      { title: "Meus dados", to: "/my-data" },
      { title: "Configurações", to: "/settings" },
    ],
    title: "Home",
    logo: require("../src/assets/logo.png"),
    name: "",
    partner: {
      name: "",
      nickname: "",
      balance: 0,
    },
  }),
  computed: {
    ...mapState(["snackbar", "sessionInfo"]),
  },
  methods: {
    handleChangeTitle(title) {
      this.title = title;
    },
    handleLogout() {
      this.openExit = false;
      localStorage.removeItem("tokens");
      this.$router.push("/login");
    },
    async handleGetInfo() {
      this.loading = true;
      const getPartner = new GetPartner();
      const partner = await getPartner.execute();

      this.partner.name = partner.name || "";
      this.partner.nickname = partner.nickname || "";
      this.partner.balance = partner.document || "";

      this.loading = false;
    },
  },
  mounted() {
    this.handleGetInfo();
  },
};
</script>

<style scoped>
.custom-gradient {
  background: #e5183d;
}

/* Adicione esta classe ao v-navigation-drawer */
.v-navigation-drawer--fixed.custom-gradient {
  background: #e5183d !important;
}

@media print {
  .no-print {
    display: none !important;
  }
}
</style>
